import React from 'react';
import { Helmet } from 'react-helmet';
import { Heading, Paragraph } from '@lux/components-gomo';
import styled from 'styled-components';
import withLayout from '../components/withLayout';
import { navigate } from 'gatsby';
import { remCalc } from '@lux/helpers';
import NavControls from '../components/NavControls';
import { ThemeProvider } from '@dls/web';

import { PRODUCT_NAME, UNABLE_RETRIEVE } from '../constants/page_content.json';
import { USER_DETAIL_PAGE } from '../constants/links.json';

const Section = styled.div`
  margin: ${remCalc(20)} 0;
  div {
    justify-content: flex-start;
  }
`;

const Failure = () => {
  return (
    <ThemeProvider brand="advance">
      <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
        <title>{UNABLE_RETRIEVE.TITLE}</title>
      </Helmet>
      <>
        <Heading level={2}>{UNABLE_RETRIEVE.HEADING}</Heading>
        <Section>
          <Paragraph>{UNABLE_RETRIEVE.DESCRIPTION}</Paragraph>
        </Section>
        <Section>
          <NavControls
            onNextButtonClick={() => navigate(USER_DETAIL_PAGE.RELATIVE)}
            nextButtonText="FILL FORM MANUALLY"
          />
        </Section>
      </>
    </ThemeProvider>
  );
};

export default withLayout(Failure, true);
